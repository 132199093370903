import { useState, useEffect } from "react";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from "moment";
import CustomDatePicker from "../../../shared/components/CustomDatePicker";
import { Autocomplete, TextField } from "@mui/material";
import CustomTimePicker from "../../../shared/components/CustomTimePicker";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../../../data/user';
import { getCompanyById, Companies } from "../../../../../../data/company";
import { Absences } from "../../../../../../data/absences";

export default function AusenciaForm({
    onSubmitAusencia
}) {
    const ausenciaSchema = yup.object({
        fecha_inicio: yup.string().required("La fecha inicial es obligatoria"),
        fecha_fin:  yup.string().required("La fecha final es obligatoria"),
        tipo_ausencia_id: yup.number().integer().required("El tipo de ausencia es obligatorio"),
    }).required();
    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        setValue, 
        getValues,
        watch } = useForm({
            resolver: yupResolver(ausenciaSchema),
            defaultValues: {
                fecha_inicio: '',
                fecha_fin: '',
                hora_inicio: '',
                hora_fin: '',
                tipo_ausencia_id: null,
                user_id: null,
                user: null
            }
    });


    
    // const user = JSON.parse(localStorage.getItem('user'));
    const { keycloak } = useKeycloak();

    const tipoAusencia = watch('tipo_ausencia_id');
    const isAusenciaJustificada = () => {
        return parseInt(tipoAusencia) === Absences.AUSENCIA_JUSTIFICADA.id;
    };


    const user = new User(keycloak.token, keycloak.tokenParsed)
    // View states
    const [currentWorker, setCurrentWorker ] = useState(null);
    const [excluyentDays, setExcluyentDays] = useState(null);
    const [isJanuary, setIsJanuary] = useState(false);
    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });
    const fiestasNacionales = useSelector(state => {
        return state.fiestas
    });
    const users = useSelector(state => {
        return state.users.entities
    });

    useEffect(() => {
        users.find(workerCandidate => 
            {
                if(workerCandidate.uuid === user.id) {
                    setValue('user', workerCandidate);
                    setValue('user_id',  workerCandidate.uuid);
                    setCurrentWorker(workerCandidate);
                    setExcluyentDaysForCalendar(workerCandidate);
                    return true
                }
                
            })
    }, []);

    const setNextWeekDay = (day) => {
        const weekDay = day.day()
        if(weekDay === 0) {
            day.add(1, 'day')
        } else if (weekDay === 6) {
            day.add(2, 'day')
        }
    }

    const itsWeekDay = (day) => {
        const itsWeekDay = (day.day() !== 0 && day.day() !== 6);
        return itsWeekDay ;
    }

    const itsThisYear = (date) => {
        const today = new Date()
        return date.year >= today.getFullYear();
    }

    const parseDate = (date) => {
        return moment(
            moment().format('YYYY') 
            + '-' 
            + (date.month < 10 ? ('0' + date.month) : date.month) 
            + '-' + (date.day < 10 ? ('0' + date.day) : date.day));
    }

    const itsCompanyHoliday = (holiday) => {
        const itsCompanyHoliday = holiday.empresa_id !== null
        return itsCompanyHoliday
    }

    const itsHolidayValidForWorker = (fiesta, worker) => {
        const workerCompany = getCompanyById(worker.departamento.empresa_id);
        if(itsCompanyHoliday(fiesta)) {
            if(fiesta.empresa_id === workerCompany.id) {
                return true
            }
        } else {
            return true
        }
        return false
    }

    const getWorkerNationalHolidays = (nationalHolidays, worker) => {
        let holidays = []
        nationalHolidays.forEach(fiesta => { 
            const holiday = parseDate(fiesta)
            if(!fiesta.infinite) {
                if(itsThisYear(fiesta)) {
                    if(itsHolidayValidForWorker(fiesta, worker)) {
                        holidays.push(holiday.format('YYYY-MM-DD'))
                    }
                }
            } else {
                if(itsHolidayValidForWorker(fiesta,worker)) {
                    holidays.push(holiday.format('YYYY-MM-DD'));
                    const nextYearDay = moment(holiday).add('year',1);
                    holidays.push(nextYearDay.format('YYYY-MM-DD'));
                }
            }
        })
        return holidays
    }

    const getUserAbsences = (userAbsences, userCompany) => {
        const today = new Date()
        const thisYear = today.getFullYear()
        let absences = []
        userAbsences.forEach(absence => {
            let absenceMoment = moment(absence.fecha_inicio)
            if(absenceMoment.year() === thisYear) {
                let absenceDay = moment(absence.fecha_inicio)
                for(let duration = 0; duration < absence.total_dias; duration++ ) {
                    
                    if(itsWeekDay(absenceDay)){
                        absences.push(absenceDay.format('YYYY-MM-DD'))
                        absenceDay.add(1, 'day');
                    } else {
                        // IF company id it's 1, so its recomotor. then just work on working days.
                        if(userCompany.id === Companies.RECOMOTOR.id || userCompany.id === Companies.DESGUACE_ONLINE.id) {
                            setNextWeekDay(absenceDay);
                        }
                        absences.push(absenceDay.format('YYYY-MM-DD'));
                        absenceDay.add(1, 'day');
                    }
                }
            }
        })
        return absences
    }

    const setExcluyentDaysForCalendar = (worker) => {
        const user = worker
        let holidays = []
        const workerCompany = getCompanyById(worker.departamento.empresa_id);
        if(workerCompany.name === Companies.RECOMOTOR.name || workerCompany.name === Companies.DESGUACE_ONLINE.name) {
            holidays = getWorkerNationalHolidays(fiestasNacionales, worker)
        }
        

        let userAbsences = []
        if(user.createdAbsences) {
            const userCompany = getCompanyById(user.departamento.empresa_id);
            userAbsences = getUserAbsences(user.createdAbsences,userCompany);
        }

        const excluyentDays = [...userAbsences, ...holidays]; 
        setExcluyentDays(excluyentDays);
    }

    const handleChangeTipoAusencia = (e) => { setValue('tipo_ausencia_id', parseInt(e.target.value)) }

    // holiday candidate is moment object
    const isHoliday = (holidayCandidate, user) => {
        const userNationalHolidays = getWorkerNationalHolidays(fiestasNacionales, user);
        const isHoliday = userNationalHolidays.some( holiday => {
            return holidayCandidate.format('YYYY-MM-DD') === holiday
        })

        return isHoliday;
    }

    const needJustificante = (tipoAusenciaId) => {
        return tipoAusencias.find(ta => ta.id === parseInt(tipoAusenciaId))?.justificante;
    } 

    const changeSelectedUser = (user) => {
        setExcluyentDaysForCalendar(user);
        setCurrentWorker(user);
    }

    const handleChangeUser = (e, user) => {
        const userId = user.uuid;
        changeSelectedUser(user);
        setValue('user', user);
        setValue('user_id',  userId);
    }


    const isBirthday = (day, user) => {
        const birthday = moment(user.birthday);
        return birthday.day() == day.day() && birthday.month() === day.month()
    }

    const onSubmit = (data, e) => {
        e.preventDefault();
        let ausencia;
        const start = moment(getValues('fecha_inicio'));
        const end = moment(getValues('fecha_fin'));
        const days = end.diff(start, 'days')+1;         // +1 inclusive
        let current = start;
        let totalDays = 0;
        
        for (let day = 0; day < days; day++) {
            if (day !== 0) {
                current = moment(current).add(1, 'day')
            }
            if(data.user.company === Companies.RECOMOTOR.name || data.user.departamento.empresa_id === Companies.RECOMOTOR.id 
                || data.user.departamento.empresa_id === Companies.DESGUACE_ONLINE.id || data.user.company === Companies.DESGUACE_ONLINE.name ) {
                // EN RECOMOTOR SE CUENTAN DIAS LABORABLES
                if(itsWeekDay(current) && !isHoliday(current, data.user) && !isBirthday(current, data.user)){
                    totalDays += 1
                }
            } else {
                // EMPRESA QUE NO SEA RECOMOTOR, SE CUENTAN DIAS NATURALES
                //aqui ahora cuentan festivos
                // if(!isHoliday(current, data.user)) totalDays += 1
                totalDays += 1
            }
        }

        data.fecha = moment().format('YYYY-MM-DD');
        data.fecha_inicio = start.format('YYYY-MM-DD');
        data.fecha_fin = end.format('YYYY-MM-DD');

        data.user_id = data.user_id !== null ? data.user_id : currentWorker.uuid;
        data.total_dias = totalDays;

        if(parseInt(data.tipo_ausencia_id) === Absences.AUSENCIA_JUSTIFICADA.id) {
            if(data.hora_inicio !== '' && data.hora_fin !== '' ) {
                data.ausencia_justificada_hours = data.hora_inicio.format('hh:mm') + ' - ' + data.hora_fin.format('hh:mm')
            }
        }
        delete data.user;
        if(data?.justificante && data?.justificante?.length > 0) {
            ausencia = new FormData();

            Object.entries(data).forEach((value) => {
                if((value[0] === 'justificante') && value[1]?.length > 0) {
                    ausencia.append(value[0], value[1][0])
                } else { ausencia.append(value[0], value[1]) }
            });
        } else ausencia = data;
        
        onSubmitAusencia(ausencia);
    };

    const worksOnWeekEnd = (worker) => {
        if(worker !== null) {
            console.log('lel')
            const workerCompany = getCompanyById(worker.departamento.empresa_id);
            const worksOnWeekEnd = (workerCompany.id !== Companies.RECOMOTOR.id && workerCompany.id !== Companies.DESGUACE_ONLINE.id);
            return worksOnWeekEnd
        }
        return false
        
    }

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column mt-2">
                        { (user.roles.includes("admin") || user.roles.includes('rrhh') || user.responsable) &&
                            <div className="form-group">
                                <label htmlFor="tipo_ausencia_id">Usuario</label>
                                { ((users && users.length > 0)) &&
                                    <Autocomplete
                                        disablePortal
                                        {...register("user", {required: true})}
                                        options={users.filter(u => u.active)}
                                        value={currentWorker}
                                        getOptionLabel={(option) =>   {
                                            if(option.nombre) {
                                                return `${option['nombre']} ${option['apellidos']}`
                                            } else {
                                                return option.name
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.uuid === value?.uuid
                                        }}
                                        onChange={handleChangeUser}
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        }
                                    />
                                }

                                <div className="form-invalid">
                                    {(errors.tipo_ausencia_id) && errors.tipo_ausencia_id?.message}
                                </div>
                            </div>
                        }

                        <div className="form-group">
                            <label htmlFor="tipo_ausencia_id">Tipo de ausencia</label>
                            { (tipoAusencias && tipoAusencias.length > 0) &&
                                <select 
                                    {...register("tipo_ausencia_id", {required: true})}
                                    defaultValue={1}
                                    onChange={handleChangeTipoAusencia}>
                                    {tipoAusencias.map(op => <option key={'tipo-ausencia-' + op.id} value={op.id}>{op.nombre}</option>) }
                                </select>
                            }

                            <div className="form-invalid">
                                {(errors.tipo_ausencia_id) && errors.tipo_ausencia_id?.message}
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-column">
                            <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                                <div className="w-100 me-0 me-md-3">
                                    <CustomDatePicker 
                                        label={"Fecha inicial"}
                                        maxDateValue={isJanuary ? moment(new Date(moment().format('YYYY'), 1, 1)).format('YYYY-MM-DD') : null}
                                        minDateValue={null}
                                        disabledDates={excluyentDays}
                                        disableWeekends={worksOnWeekEnd(currentWorker)}
                                        onChangeDate={(value) => setValue('fecha_inicio', value)}
                                    />
                                </div>    
                                
                                <CustomDatePicker 
                                    label={"Fecha final"}
                                    maxDateValue={isJanuary ? moment(new Date(moment().format('YYYY'), 1, 1)).format('YYYY-MM-DD') : null}
                                    disabledDates={excluyentDays}
                                    disableWeekends={worksOnWeekEnd(currentWorker)}
                                    minDateValue={watch("fecha_inicio")}
                                    onChangeDate={(value) => setValue('fecha_fin', value)}
                                />
                            </div>

                            <div className="form-invalid">
                                {(errors.fecha_inicio) && errors.fecha_inicio?.message}
                                {(errors.fecha_fin) && errors.fecha_fin?.message}
                            </div>
                        </div>
                        { (isAusenciaJustificada()) ?
                            <div className="w-100 d-flex flex-column">
                                <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                                    <div className="form-group me-3">
                                        <CustomTimePicker 
                                            value={null}
                                            name={'hora_inicio'}
                                            placeholder={'9:00'}
                                            onChangeTime={(value) => setValue('hora_inicio', value)}
                                        />
                                    </div>
                                    
                                    <div className="form-group">
                                        <CustomTimePicker 
                                            value={null}
                                            name={'hora_fin'}
                                            placeholder={'10:30'}
                                            onChangeTime={(value) => setValue('hora_fin', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                        { (needJustificante(watch('tipo_ausencia_id'))) &&
                            <div className="w-100 d-flex flex-column">
                                <div className="form-group">
                                    <label htmlFor="justificante">Justificante</label>
                                    <input 
                                        type="file" 
                                        className="form-control" 
                                        {...register("justificante", { maxLength: 255 })} 
                                        defaultValue={''} />
                                </div>
                            </div>
                        }
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}