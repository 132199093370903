import API from '../../../../../services/api';
import { headers } from '../helpers/functionalities';

export const getDocumentos = async (filters) => {
    return await API.get('/documentos' + (filters ? filters : '')).then(res => res.data);
}

export const getDocumentosUser = async (userId, filters) => {
    return await API.get('/documentos/' + userId + '/' + (filters ? filters : '')).then(res => res.data);
}

export const downloadUserDocument = async (typoDocumentoId, workerNumber, documentName) => {
    return await API.get(`/documentos/${typoDocumentoId}/${workerNumber}/${documentName}` , {responseType: 'blob'}).then(res => res.data);
}

export const downloadDocument = async (typoDocumentoId, documentName) => {
    return await API.get(`/documentos/${typoDocumentoId}/${documentName}` , {responseType: 'blob'}).then(res => res.data);
}

export const downloadDocumento = async (documentoId) => {
    return await API.get('/documentos/' + documentoId + '/download', {responseType: 'blob'}).then(res => res.data);
}
export const downloadJustificantDocumento = async (documento,usuario) => {
    return await API.get('/documentos/justificant/download/'+ usuario + '/' +documento, {responseType: 'blob'}).then(res => res.data);
}

export const newDocumento = async (documento) => {
    return await API.post('/documentos', documento, headers).then(res => res.data);
}

export const deleteDocumento = async (documentoId) => {
    return await API.delete('/documentos/' + documentoId).then(res => res.data);
}