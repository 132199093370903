import { useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFileExcel, faList, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, TextField } from "@mui/material";
import DateSelectorSimple from "../../../shared/components/DateSelectorSimple";
import { getCompanyById } from "../../../../../../data/company";

export default function Header({
    user,
    users,
    departamento,
    departamentos,
    company,
    companies,
    activeDate,
    onChangeDepartamento,
    onChangeUser,
    onChangeMonth,
    onChangeCompany,
    onOpenNew,
    onOpenPendientes,
    onOpenResumen,
    onOpenExport,
    selectedUser
}) {

    const tipoAusencias = useSelector(state => {
        return state.tipo_ausencias
    });

    const [options, setOptions] = useState([]);

    useEffect(() => {
        let newOps = [{id: -1, nombre: 'Todos'}];

        if(users) {
            newOps = [...newOps, ...users];
        }

        setOptions(newOps);
    });

    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-between my-4">
                <div className="d-flex align-items-center">
                { (user.roles.includes('admin') || user.roles.includes('responsable') || user.roles.includes('rrhh')) &&
                        <div className="me-3">
                            <label>Empresa:</label>
                            <select 
                                id="company_id"
                                name="company_id"
                                defaultValue={company}
                                onChange={onChangeCompany}>
                                <option value={-1}>Todos</option>
                                {companies?.map(company => {
                                    return <option key={company.id} value={company.id}>{company.nombre}</option>
                                    })}
                            </select>
                            </div>
                    }    
                    
                    <div className="me-3">
                        <label>Departamento:</label>
                        <select 
                            id="departamento_id"
                            name="departamento_id"
                            // defaultValue={departamento}
                            onChange={onChangeDepartamento}>
                            <option value={-1}>Todos</option>
                            {departamentos?.map(dep => {
                                const companyDepartment = getCompanyById(dep.empresa_id)
                                if( (user.company === companyDepartment.name || user.roles.includes('admin') || user.roles.includes('rrhh')) && dep.id !== 9  ) {
                                    return <option selected={dep.id === departamento} key={'departamentos-' + dep.id} value={dep.id}>{dep.nombre}</option>
                                }
                                })}
                        </select>
                    </div>
                        
                    

                    <div className="me-3">
                        <label>Empleado:</label>
                        <Autocomplete
                            disablePortal
                            id="owner_id"
                            options={options}
                            defaultValue={selectedUser}
                            getOptionLabel={(option) =>   `${option['nombre']} ${option['apellidos'] ? option['apellidos'] : ''}`}
                            onChange={onChangeUser}
                            isOptionEqualToValue={(option, value) => {
                                return option.uuid === value?.uuid
                            }}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            }
                        />
                    </div>

                    <DateSelectorSimple
                        currDay={activeDate}  
                        onSelectedDate={onChangeMonth}
                    />
                </div>
                
                <div className="d-flex align-items-center">
                    { (user.roles.includes('admin') || user.roles.includes('responsable') || user.roles.includes('rrhh')) &&
                        <>
                            <button className="btn btn-add me-3" onClick={() => onOpenExport()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" />
                                </span>
                                Exportar
                            </button>

                            <button className="btn btn-add me-3" onClick={() => onOpenResumen()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faList} size="1x" className="action" color="#215732" />
                                </span>
                                Resumen
                            </button>
                        </>
                    }
                    {/* { (!user.roles.includes('admin') && !user.roles.includes('responsable') && !user.roles.includes('rrhh')) &&
                        <>
                            <button className="btn btn-add me-3" onClick={() => onOpenUploadDocument()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faUpload} size="1x" className="action" color="#215732" />
                                </span>
                                Subir Documento
                            </button>
                        </>
                    } */}

                    { (user.roles.includes('admin') || user.roles.includes('rrhh')) &&
                        <>
                            <button className="btn btn-add me-3" onClick={() => onOpenPendientes()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faClock} size="1x" className="action" color="#215732" />
                                </span>
                                Pendientes
                            </button>
                        </>
                    }
                    
                    <button className="btn btn-add" onClick={() => onOpenNew()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nueva ausencia
                    </button>
                </div>
            </div>

            <div className="w-100 d-flex">
                { tipoAusencias?.map((tipo, i) => {
                    return (
                        <div key={'legend-' + tipo.id} className={"d-flex align-items-center me-3"}>
                            <span className="circle me-1" style={{backgroundColor: tipo.color}}></span>
                            <small>{tipo.nombre}</small>
                        </div>
                    )
                }) }

                <div className={"d-flex align-items-center"}>
                    <span className="circle me-1" style={{backgroundColor: '#a8f8c1'}}></span>
                    <small>Fiesta Nacional</small>
                </div>
            </div>
        </div>
    );
}