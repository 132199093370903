import { getUsers } from "../views/Main/components/shared/services/users";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer } from "./utils";

const asyncUsers = makeAsyncTypes('users');

const [setPending, setFullFilled, setError ] = asyncMac(asyncUsers);

export const usersFetchingReducer = makeFetchingReducer([
    'users/pending', 
    'users/fullfilled', 
    'users/rejected'
]);

const fullfilledReducer = makeSetReducer(['users/fullfilled']);

export const fetchUsers = (empresaId = null, depId = null) => async dispatch => {
    dispatch(setPending());
    
    try {
        const response = await getUsers(empresaId, depId)
        const data = await response.users;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const usersReducer = fullfilledReducer;
export const usersStatusReducer = usersFetchingReducer;