import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import CustomDatePicker from "../../../../shared/components/CustomDatePicker";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import { fetchUsers } from "../../../../../../../redux/users";

export default function DocumentoForm({
    onSubmitDocumento
}) {
    const documentoSchema = yup.object({
        fecha: yup.string().required("La fecha es obligatoria"),
        tipo_documento_id: yup.number().integer().moreThan(0).required("El tipo de documento es obligatorio"),
        
    }).required();
    const dispatch = useDispatch();

    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        setValue,
        watch,
     } = useForm({
            resolver: yupResolver(documentoSchema),
            defaultValues: {
                id: '',
                fecha: '',
                tipo_documento_id: -1,
                owner_id: null,
                owner: null
            }
    });

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const users = useSelector(state => {
        return state.users.entities.filter(user => user.active)
    });
    const tipoDocumentos = useSelector(state => {
        return state.tipo_documentos
    });



    const handleChangeUser = (e, user) => { 
        if(user) {
            setValue('owner_id',  user.id);
        }
        
        setValue('owner', user);
    }

    const handleChangeTipoDocumento = (e) => { 
        const value = parseInt(e.target.value);
        setValue('tipo_documento_id',  value);

        if(value === 1) setValue('owner_id', null);
    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
        delete data.owner;
        const documento = new FormData();

        Object.entries(data).forEach((value) => {
            if((value[0] === 'documento') && value[1]?.length > 0) {
                documento.append(value[0], value[1][0])
            } else { 
                if(value[0] === 'fecha') {
                    documento.append(value[0], moment(value[1]));
                } else documento.append(value[0], value[1]);
            }
        });

        onSubmitDocumento(documento);
    }

    return (
        <div className="w-100">
           <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column mt-2">
                        <div className="form-group">
                            <label htmlFor="tipo_documento_id">Tipo de documento</label>
                            { ((tipoDocumentos && tipoDocumentos.length > 0)) &&
                                <select 
                                    {...register("tipo_documento_id", {required: true})}
                                    defaultValue={null}
                                    onChange={handleChangeTipoDocumento}>
                                        <option value={-1}>Selecciona una opción...</option>
                                        {tipoDocumentos.map(op => <option key={'tipo-documento-' + op.id} value={op.id}>{op.nombre}</option>)}
                                </select>
                            }

                            <div className="form-invalid">
                                {(errors.tipo_documento_id) && errors.tipo_documento_id?.message}
                            </div>
                        </div>

                        { watch('tipo_documento_id') !== 1 &&
                            <div className="form-group">
                                <label htmlFor="owner_id">Empleado</label>
                                { ((users && users.length > 0)) &&
                                    <Autocomplete
                                        disablePortal
                                        {...register("owner_id", {required: true})}
                                        options={users}
                                        defaultValue={null}
                                        getOptionLabel={(option) =>   `${option['nombre']}`}
                                        onChange={handleChangeUser}
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        }
                                    />
                                }

                                <div className="form-invalid">
                                    {(errors.owner_id) && errors.owner_id?.message}
                                </div>
                            </div>
                        }
                        

                        <div className="form-group">
                            <label htmlFor="documento">Documento</label>
                            <input 
                                type="file" 
                                className="form-control" 
                                {...register("documento", { required: true, maxLength: 255 })} 
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.documento) && "El documento es obligatorio!"}
                            </div>
                        </div>

                        <CustomDatePicker 
                            label={"Fecha"}
                            disableFuture={false}
                            minDateValue={null}
                            onChangeDate={(value) => setValue('fecha', value)}
                        />
                    </div>

                    <div className="w-100 d-flex justify-content-end mt-2">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}