import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../../../../redux/empleado";
import Loading from "../../shared/components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFilePdf, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import { Switch } from "@mui/material";
import userDefault from '../../../../../images/user.png';
import { getCvUser, updateUser } from "../../shared/services/users";
import { toast } from "react-toastify";
import { setErrorMessage } from "../../shared/helpers/functionalities";

export default function FichaEmpleado() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId } = useParams();
    
    const user = useSelector(state => {
        return state.empleado.entity
    });
    const userStatus = useSelector(state => {
        return state.empleado.status
    });
    const nivelEstudios = useSelector(state => {
        const { nivel_estudios } = state;
        return nivel_estudios;
    });
    const departamentos = useSelector(state => {
        const { departamentos } = state;
        return departamentos;
    });
    const tipoContratos = useSelector(state => {
        const { tipo_contratos } = state;
        return tipo_contratos;
    });
    const empresas = useSelector(state => {
        const { empresas } = state;
        return empresas;
    });

    const { 
        register, formState: { errors }, handleSubmit, reset, setValue, watch 
    } = useForm({
        defaultValues: {
            id: user.uuid,
            nombre: user.nombre,
            apellidos: user.apellidos,
            birthday: user.birthday ? moment(user.birthday) : null,
            nivel_estudios_id: user.nivel_estudios_id ? user.nivel_estudios_id : '',
            genero: user.genero ? user.genero : '',
            nacionalidad: user.nacionalidad ? user.nacionalidad : '',
            dni: user.dni ? user.dni : '',
            fecha_caducidad_dni: user.fecha_caducidad_dni ? user.fecha_caducidad_dni : '',
            iban: user.iban ? user.iban : '',
            naf: user.naf ? user.naf : '',
            telefono: user.telefono ? user.telefono : '',
            email: user.email ? user.email : '',
            direccion: user.direccion ? user.direccion : '',
            persona_contacto: user.persona_contacto ? user.persona_contacto : '',
            telefono_persona_contacto: user.telefono_persona_contacto ? user.telefono_persona_contacto : '',
            dep_id: user.dep_id ? user.dep_id : '',
            tipo_contrato_id: user.tipo_contrato_id ? user.tipo_contrato_id : '',
            ubicacion_trabajo: user.ubicacion_trabajo ? user.ubicacion_trabajo : '',
            cargo: user.cargo ? user.cargo : '',
            start_on: user.start_on ? moment(user.start_on) : null,
            end_on: user.end_on ? moment(user.end_on) : null,
            jornada_completa: user.jornada_completa ? user.jornada_completa : '',
            media_jornada: user.media_jornada ? user.media_jornada : '',
            horario: user.horario ? user.horario : '',
            foto_file: '',
            cv_file: '',
            annexo_imagen:  user.annexo_imagen !== null ? (user.annexo_imagen === true ? 'SI' : 'NO') : null,
            annexo_vacaciones:  user.annexo_vacaciones !== null ? (user.annexo_vacaciones === true ? 'SI' : 'NO') : null,
            formacion_riesgos: user.formacion_riesgos !== null ? (user.formacion_riesgos === true ? 'SI' : 'NO') : null,
            formacion_riesgos_year: user.formacion_riesgos_year ? user.formacion_riesgos_year : '',
            reconocimiento_medico: user.reconocimiento_medico ? user.reconocimiento_medico : '',
            reconocimiento_medico_year: user.reconocimiento_medico_year ? user.reconocimiento_medico_year : ''
        }
    });

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        dispatch(fetchUser(userId)); 
    }, []);

    useEffect(() => {
        reset({
            id: user.uuid,
            nombre: user.nombre,
            apellidos: user.apellidos,
            birthday: user.birthday ? moment(user.birthday) : null,
            nivel_estudios_id: user.nivel_estudios_id ? user.nivel_estudios_id : '',
            genero: user.genero ? user.genero : '',
            nacionalidad: user.nacionalidad ? user.nacionalidad : '',
            dni: user.dni ? user.dni : '',
            fecha_caducidad_dni: user.fecha_caducidad_dni ? user.fecha_caducidad_dni : '',
            iban: user.iban ? user.iban : '',
            naf: user.naf ? user.naf : '',
            telefono: user.telefono ? user.telefono : '',
            email: user.email ? user.email : '',
            direccion: user.direccion ? user.direccion : '',
            persona_contacto: user.persona_contacto ? user.persona_contacto : '',
            telefono_persona_contacto: user.telefono_persona_contacto ? user.telefono_persona_contacto : '',
            dep_id: user.dep_id ? user.dep_id : '',
            tipo_contrato_id: user.tipo_contrato_id ? user.tipo_contrato_id : '',
            ubicacion_trabajo: user.ubicacion_trabajo ? user.ubicacion_trabajo : '',
            cargo: user.cargo ? user.cargo : '',
            start_on: user.start_on ? moment(user.start_on) : null,
            end_on: user.end_on ? moment(user.end_on) : null,
            jornada_completa: user.jornada_completa ? user.jornada_completa : '',
            media_jornada: user.media_jornada ? user.media_jornada : '',
            horario: user.horario ? user.horario : '',
            foto_file: '',
            cv_file: '',
            annexo_imagen:  user.annexo_imagen !== null ? (user.annexo_imagen === true ? 'SI' : 'NO') : null,
            annexo_vacaciones:  user.annexo_vacaciones !== null ? (user.annexo_vacaciones === true ? 'SI' : 'NO') : null,
            formacion_riesgos: user.formacion_riesgos !== null ? (user.formacion_riesgos === true ? 'SI' : 'NO') : null,
            formacion_riesgos_year: user.formacion_riesgos_year ? user.formacion_riesgos_year : '',
            reconocimiento_medico: user.reconocimiento_medico ? user.reconocimiento_medico : '',
            reconocimiento_medico_year: user.reconocimiento_medico_year ? user.reconocimiento_medico_year : ''
        });
    }, [user]);

    const handleEditUser = () => {
        reset(user)
        setIsEdit(true);
    }
    const handleCancelEdit = () => {
        setIsEdit(false);
        reset();
    }

    const handleBack = () => navigate(-1);

    const handleChangeIban = (e) => {
        const value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        setValue('iban', value);
    }
    const handleChangeGenero = (e) => { setValue('genero', e.target.value); }
    const handleChangeReconocimientoMedico = (e) => { setValue('reconocimiento_medico', e.target.value); }
    const handleChangeNivelEstudios = (e) => { setValue('nivel_estudios_id', parseInt(e.target.value)); }
    const handleChangeDep = (e) => { setValue('dep_id', parseInt(e.target.value)); }
    const handleChangeJornada = (e) => { setValue('jornada_completa', e.target.checked); }
    const handleChangeMediaJornada = (e) => { setValue('media_jornada', e.target.value); }

    const getJornadaParcial = () => {
        if(user.horario) {
            const currDay = moment();
            const horarioUser = user.horario.split('-');
            const morningStartHour = horarioUser[0].split(':');
            const morningEndHour = horarioUser[1].split(':');
            const morningStart = moment(currDay);
            morningStart.hour(parseInt(morningStartHour[0])).minute(parseInt(morningStartHour[1])).second(0);
            const morningEnd = moment(currDay);
            morningEnd.hour(parseInt(morningEndHour[0])).minute(parseInt(morningEndHour[1])).second(0);
            const totalHours = moment.duration(morningEnd.diff(morningStart)).asHours();
            
            return `Parcial de ${user.media_jornada === 'morning' ? 'mañanas' : 'tardes'}, ${totalHours * 5}h semanales`;
        } else {
            return 'Parcial, no hay horario establecido'
        }
    }

    const onSubmit = async(data, e) => {
        e.preventDefault();
        let user;
        data.birthday = moment(data.birthday).format('YYYY-MM-DD');
        data.start_on = moment(data.start_on).format('YYYY-MM-DD');
        data.end_on = data.end_on ? moment(data.end_on).format('YYYY-MM-DD') : null;
        data.media_jornada = data.jornada_completa ? null : data.media_jornada;
        data.horario = data.jornada_completa ? null : data.horario;
        data.annexo_imagen = data.annexo_imagen ? (data.annexo_imagen === 'SI' ? true : false) : null;
        data.annexo_vacaciones = data.annexo_vacaciones ? (data.annexo_vacaciones === 'SI' ? true : false) : null;
        data.formacion_riesgos = data.formacion_riesgos ? (data.formacion_riesgos === 'SI' ? true : false) : null;

        // UPDATE USER DATA
        let newData = Object.fromEntries(Object.entries(data).filter(([_, v]) => {
            if(_ !== 'foto_file' && _ !== 'cv_file') return v !== "" && v !== null
            else return v !== null && v?.length > 0
        }));
        
        if(newData?.foto_file || newData.cv_file) {
            user = new FormData();

            Object.entries(newData).forEach((value) => {
                if((value[0] === 'foto_file' || value[0] === 'cv_file') && value[1]?.length > 0) {
                    user.append(value[0], value[1][0])
                } else { user.append(value[0], value[1]) }
            });
        } else user = newData;

        const response = await updateUser(user)
        .catch((error) => {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            toast.success(response.message);
            dispatch(fetchUser(userId));
        }
    }

    const handleDownloadCV = async() => {
        const response = await getCvUser(user.uuid)
        .catch((error) => {
            toast.error(setErrorMessage(error))
        });

        if(response) {
            toast.success('CV descargado correctamente!')
          
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('download', user.cv);
            document.body.appendChild(link);
            link.click();
        }
    }
  
    return (
        <div className="w-100 content">
            { (userStatus.loading === 'pending' || userStatus.loading === 'idle') &&
                <Loading />
            }

            { (userStatus.loading === 'succeed' || userStatus.loading === 'rejected') &&
                <div className="w-100 d-flex flex-column align-items-center mt-3">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img 
                                src={user?.foto ? `data:application/pdf;base64,${user.foto_src}` : userDefault} 
                                alt="Foto empleado" 
                                width={"100px"} 
                                height={"100px"} 
                                className="rounded-circle"
                                style={{objectFit: 'cover'}}
                            />
                            <h1 className="ms-3">{user.nombre} {user.apellidos}</h1>
                        </div>


                        <div className="d-flex align-items-center">
                            <button className="btn btn-add me-3" onClick={() => handleBack()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faLongArrowAltLeft} size="1x" className="action" color="#215732" />
                                </span>
                                Atrás
                            </button>

                            <button className="btn btn-add" onClick={() => handleEditUser()}>
                                <span className="add">
                                    <FontAwesomeIcon icon={faEdit} size="1x" className="action" color="#215732" />
                                </span>
                                Editar
                            </button>
                        </div>
                    </div>
                    
                    <div className="d-flex col-12 col-md-8 mt-3">
                        <form className="w-100 form align-items-stretch" onSubmit={handleSubmit(onSubmit)}>
                            <input 
                                type="hidden" 
                                {...register("id")} 
                                defaultValue={userId} />

                            <div className="w-100 mb-3">
                                <h5 className="mb-4">Información personal</h5>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="nombre">Nombre</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("nombre", { required: true, maxLength: 50 })} 
                                                    defaultValue={user.nombre} />
                                                
                                                <div className="form-invalid">
                                                    {errors.nombre?.type === 'required' && "El nombre es obligatorio"}
                                                </div>
                                            </>
                                            :
                                            <p>{user.nombre}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="apellidos">Apellidos</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("apellidos", { required: true, maxLength: 50 })} 
                                                    defaultValue={user.apellidos} />
                                                
                                                <div className="form-invalid">
                                                    {errors.apellidos?.type === 'required' && "Los apellidos son obligatorios"}
                                                </div>
                                            </>
                                            :
                                            <p>{user.apellidos}</p>
                                        }
                                            
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    { (isEdit) ? 
                                        <>
                                            <CustomDatePicker 
                                                label={"Cumpleaños"}
                                                disableFuture={true}
                                                minDateValue={null}
                                                marginEnd={true}
                                                value={user.birthday ? moment(user.birthday) : null}
                                                onChangeDate={(value) => setValue('birthday', value)}
                                            />
                                            
                                            <div className="form-invalid">
                                                {errors.birthday?.type === 'required' && "La fecha de cumpleaños es obligatoria"}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="form-group me-3">
                                                <label>Cumpleaños</label>
                                                <p>{user.birthday ? moment(user.birthday).format('DD-MM-YYYY') : '-'}</p>
                                            </div>
                                        </>
                                    }

                                    <div className="form-group">
                                        <label htmlFor="nivel_estudios_id">Nivel de estudios</label>
                                        { (isEdit) ? 
                                            <>
                                                <select 
                                                    defaultValue={user.nivel_estudios_id}
                                                    {...register("nivel_estudios_id")}
                                                    onChange={handleChangeNivelEstudios}>
                                                        <option value={""}>Selecciona un nivel de estudios...</option>
                                                    { nivelEstudios?.map(ne => 
                                                        <option key={`ne-${ne.id}`} value={ne.id}>{ne.nombre}</option>
                                                    )}
                                                </select>
                                            </>
                                            :
                                            <p>{user.nivel_estudios_id ? (user.nivelEstudios.nombre) : '-'}</p>
                                        }
                                            
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="genero">Género</label>
                                        { (isEdit) ? 
                                            <>
                                                <select 
                                                    defaultValue={user.genero}
                                                    {...register("genero")}
                                                    onChange={handleChangeGenero}>
                                                    <option value={""}>Selecciona un genero...</option>
                                                    <option value={"F"}>Femenino</option>
                                                    <option value={"M"}>Masculino</option>
                                                    <option value={"O"}>Otro</option>
                                                </select>
                                            </>
                                            :
                                            <p>
                                                {user.genero ? 
                                                    (user.genero === 'F' ? 'Femenino' 
                                                    : user.genero === 'M' ? 'Masculino' 
                                                    : 'Otros'
                                                ) : '-'}
                                            </p>
                                        } 
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="nacionalidad">Nacionalidad</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("nacionalidad", { maxLength: 50 })} 
                                                    defaultValue={user.nacionalidad} />
                                            </>
                                            :
                                            <p>{user.nacionalidad ? user.nacionalidad : '-'}</p>
                                        }
                                            
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="dni">DNI</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("dni", { maxLength: 10 })} 
                                                    defaultValue={user.dni} />
                                            </>
                                            :
                                            <p>{user.dni}</p>
                                        }
                                            
                                    </div>

                                    { (isEdit) ? 
                                        <>
                                            <CustomDatePicker 
                                                label={"Fecha caducidad DNI"}
                                                disableFuture={false}
                                                minDateValue={null}
                                                value={user.fecha_caducidad_dni ? moment(user.fecha_caducidad_dni) : null}
                                                onChangeDate={(value) => setValue('fecha_caducidad_dni', value)}
                                            />
                                        </>
                                        :
                                        <>
                                            <div className="form-group">
                                                <label>Fecha caducidad DNI</label>
                                                <p>{user.fecha_caducidad_dni ? moment(user.fecha_caducidad_dni).format('DD-MM-YYYY') : '-'}</p>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="iban">IBAN</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    maxLength={29}
                                                    {...register("iban", { maxLength: 29 })}
                                                    defaultValue={user.iban} 
                                                    onChange={(e) => handleChangeIban(e)}
                                                />
                                            </>
                                            :
                                            <p>{user.iban ? user.iban : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="naf">NAF</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    maxLength={12}
                                                    {...register("naf", { maxLength: 12 })} 
                                                    defaultValue={user.naf} />
                                            </>
                                            :
                                            <p>{user.naf ? user.naf : '-'}</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="email">Email</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("email", { maxLength: 255 })} 
                                                    defaultValue={user.email} />
                                            </>
                                            :
                                            <p>{user.email}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="telefono">Teléfono</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("telefono", { maxLength: 15 })} 
                                                    defaultValue={user.telefono} />
                                            </>
                                            :
                                            <p>{user.telefono}</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group">
                                        <label htmlFor="direccion">Dirección</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("direccion", { maxLength: 255 })} 
                                                    defaultValue={user.direccion} />
                                            </>
                                            :
                                            <p>{user.direccion ? user.direccion : '-'}</p>
                                        }
                                            
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="persona_contacto">Persona contacto</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("persona_contacto", { maxLength: 255 })} 
                                                    defaultValue={user.persona_contacto} />
                                            </>
                                            :
                                            <p>{user.persona_contacto ? user.persona_contacto : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="telefono_persona_contacto">Teléfono de persona de contacto</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("telefono_persona_contacto", { maxLength: 15 })} 
                                                    defaultValue={user.telefono_persona_contacto} />
                                            </>
                                            :
                                            <p>{user.telefono_persona_contacto ? user.telefono_persona_contacto : '-'}</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    { (isEdit) && 
                                        <div className="form-group">
                                            <label htmlFor="foto_file">Foto</label>
                                            <input 
                                                type="file" 
                                                className="form-control" 
                                                {...register("foto_file", { maxLength: 255 })} 
                                                defaultValue={""} />
                                        </div>
                                    }
                                </div>
                            </div>

                            <hr className="d-block" />

                            <div className="w-100 mt-3">
                                <h5 className="mb-4">Información laboral</h5>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="empresa_id">Empresa</label>
                                        { (isEdit) ? 
                                            <>
                                                <select 
                                                    defaultValue={user.empresa_id}
                                                    {...register("empresa_id")}
                                                    onChange={handleChangeDep}>
                                                        <option value={""}>Selecciona una empresa...</option>
                                                    { empresas?.map(e => 
                                                        <option key={`empresa-${e.id}`} value={e.id}>{e.nombre}</option>
                                                    )}
                                                </select>
                                            </>
                                            :
                                            <p>{user.empresa_id ? (user.empresa?.nombre.toUpperCase()) : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group me-3">
                                        <label htmlFor="dep_id">Departamento</label>
                                        { (isEdit) ? 
                                            <>
                                                <select 
                                                    defaultValue={user.dep_id}
                                                    {...register("dep_id")}
                                                    onChange={handleChangeDep}>
                                                        <option value={""}>Selecciona un departamento...</option>
                                                    { departamentos?.map(ne => 
                                                        <option key={`dp-${ne.id}`} value={ne.id}>{ne.nombre}</option>
                                                    )}
                                                </select>
                                            </>
                                            :
                                            <p>{user.dep_id ? (user.departamento.nombre.toUpperCase()) : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="tipo_contrato_id">Tipo de contrato</label>
                                        { (isEdit) ? 
                                            <>
                                                <select 
                                                    defaultValue={user.tipo_contrato_id}
                                                    {...register("tipo_contrato_id")}
                                                    onChange={handleChangeNivelEstudios}>
                                                        <option value={""}>Selecciona un tipo de contrato...</option>
                                                    { tipoContratos?.map(ne => 
                                                        <option key={`ne-${ne.id}`} value={ne.id}>{ne.nombre}</option>
                                                    )}
                                                </select>
                                            </>
                                            :
                                            <p>{user.tipo_contrato_id ? (user.tipoContrato.nombre.toUpperCase()) : '-'}</p>
                                        }
                                            
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="ubicacion_trabajo">Ubicación</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("ubicacion_trabajo", { maxLength: 255 })} 
                                                    defaultValue={user.ubicacion_trabajo} />
                                            </>
                                            :
                                            <p>{user.ubicacion_trabajo ? user.ubicacion_trabajo : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="cargo">Cargo</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("cargo", { maxLength: 50 })} 
                                                    defaultValue={user.cargo} />
                                            </>
                                            :
                                            <p>{user.cargo ? user.cargo : '-'}</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    { (isEdit) ? 
                                        <>
                                            <CustomDatePicker 
                                                label={"Fecha inicio"}
                                                disableFuture={true}
                                                minDateValue={null}
                                                marginEnd={true}
                                                value={user.start_on ? moment(user.start_on) : null}
                                                onChangeDate={(value) => setValue('start_on', value)}
                                            />
                                        </>
                                        :
                                        <>
                                            <div className="form-group me-3">
                                                <label>Fecha inicio</label>
                                                <p>{user.start_on ? moment(user.start_on).format('DD-MM-YYYY') : '-'}</p>
                                            </div>
                                        </>
                                    }

                                    { (isEdit) ? 
                                        <>
                                            <CustomDatePicker 
                                                label={"Fecha fin"}
                                                disableFuture={true}
                                                minDateValue={null}
                                                value={user.end_on ? moment(user.end_on) : null}
                                                onChangeDate={(value) => setValue('end_on', value)}
                                            />
                                        </>
                                        :
                                        <>
                                            <div className="form-group">
                                                <label>Fecha fin</label>
                                                <p>{user.end_on ? moment(user.end_on).format('DD-MM-YYYY') : '-'}</p>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="jornada_completa">Jornada</label>
                                        { (isEdit) ? 
                                            <>
                                                <div className="d-flex align-items-center">
                                                    <Switch 
                                                        color="success"
                                                        defaultChecked={user ? user.jornada_completa : true}
                                                        {...register("jornada_completa")}
                                                        onChange={handleChangeJornada}
                                                        inputProps={{ 'aria-label': 'controlled' }} /> 
                                                        <label className="m-0">completa</label>
                                                </div>
                                            </>
                                            :
                                            <p>{user.jornada_completa ? 'Completa, 40h semanales' : getJornadaParcial()}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        { (isEdit) ? 
                                            <>
                                                { (watch('jornada_completa') === false) &&
                                                    <div className="w-100 d-flex align-items-stretch">
                                                        <div className="form-group me-3">
                                                            <label htmlFor="media_jornada">Horario de</label>
                                                            <select 
                                                                defaultValue={user.media_jornada ? user.media_jornada : ''}
                                                                {...register("media_jornada")}
                                                                onChange={handleChangeMediaJornada}>
                                                                <option value={""}>Selecciona un horario...</option>
                                                                <option value={"morning"}>Mañana</option>
                                                                <option value={"afternoon"}>Tarde</option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="horario">Horario</label>
                                                            <input 
                                                                type="text" 
                                                                className="form-control" 
                                                                {...register("horario", { maxLength: 255 })} 
                                                                defaultValue={user ? user.horario : ''} 
                                                                placeholder="09:00-13:30"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                <label htmlFor="cargo">Horario</label>
                                                <p>{user.horario ? user.horario : '-'}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <hr className="d-block" />

                            <div className="w-100 mt-3">
                                <h5 className="mb-4">Otros</h5>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group">
                                        <label htmlFor="cv_file">CV</label>
                                        { (isEdit) ? 
                                            <>
                                                <input 
                                                    type="file" 
                                                    className="form-control" 
                                                    {...register("cv_file", { maxLength: 255 })} 
                                                    defaultValue={""} />
                                            </>
                                            :
                                            <p>
                                                { (user.cv) ? 
                                                    <span 
                                                        title="Descargar" 
                                                        onClick={() => handleDownloadCV()}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <FontAwesomeIcon icon={faFilePdf} size="1x" color="#215732" />
                                                        <span className="ms-2">{user.cv}</span>
                                                    </span>
                                                : '-'
                                                }
                                            </p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group">
                                        <label htmlFor="observaciones">Observaciones</label>
                                        { (isEdit) ? 
                                            <>
                                                <textarea
                                                    {...register("observaciones")}
                                                    defaultValue={user.observaciones}
                                                    rows={5}
                                                ></textarea>
                                            </>
                                            :
                                            <p>{user.observaciones ? user.observaciones : '-'}</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="annexo_imagen">Annexo imágen</label>
                                        { (isEdit) ? 
                                            <div className="d-flex align-items-center justify-content-start">
                                                <div>
                                                    <input
                                                        {...register("annexo_imagen")}
                                                        type="radio"
                                                        name="annexo_imagen"
                                                        value={"SI"}
                                                        id="annexo_imagen"
                                                        defaultChecked={user.annexo_imagen !== null ? (user.annexo_imagen ? true : false) : null}
                                                    />
                                                    &nbsp; <b>SI</b>
                                                </div>

                                                <div className="ms-4">
                                                    <input
                                                        {...register("annexo_imagen")}
                                                        type="radio"
                                                        name="annexo_imagen"
                                                        value={"NO"}
                                                        id="annexo_imagen"
                                                        defaultChecked={user.annexo_imagen !== null ? (!user.annexo_imagen ? true : false) : null}
                                                    />
                                                    &nbsp; <b>NO</b>
                                                </div>
                                            </div>
                                            :
                                            <p>{user.annexo_imagen !== null ? (user.annexo_imagen === true ? 'SI' : 'NO') : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="annexo_vacaciones">Annexo vacaciones</label>
                                        { (isEdit) ? 
                                            <div className="d-flex align-items-center justify-content-start">
                                                <div>
                                                    <input
                                                        {...register("annexo_vacaciones")}
                                                        type="radio"
                                                        name="annexo_vacaciones"
                                                        value={"SI"}
                                                        id="annexo_vacaciones"
                                                        defaultChecked={user.annexo_vacaciones !== null ? (user.annexo_vacaciones ? true : false) : null}
                                                    />
                                                    &nbsp; <b>SI</b>
                                                </div>

                                                <div className="ms-4">
                                                    <input
                                                        {...register("annexo_vacaciones")}
                                                        type="radio"
                                                        name="annexo_vacaciones"
                                                        value={"NO"}
                                                        id="annexo_vacaciones"
                                                        defaultChecked={user.annexo_vacaciones !== null ? (!user.annexo_vacaciones ? true : false) : null}
                                                    />
                                                    &nbsp; <b>NO</b>
                                                </div>
                                            </div>
                                            :
                                            <p>{user.annexo_vacaciones !== null ? (user.annexo_vacaciones === true ? 'SI' : 'NO') : '-'}</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-stretch">
                                    <div className="form-group me-3">
                                        <label htmlFor="formacion_riesgos">Formación Prevención Riesgos Laborales</label>
                                        { (isEdit) ? 
                                            <div className="d-flex align-items-stretch">
                                                <div className="mt-1">
                                                    <input
                                                        {...register("formacion_riesgos")}
                                                        type="radio"
                                                        name="formacion_riesgos"
                                                        value={"SI"}
                                                        defaultChecked={user.formacion_riesgos !== null ? (user.formacion_riesgos ? true : false) : null}
                                                        id="formacion_riesgos"
                                                    />
                                                    &nbsp; <b>SI</b>
                                                </div>

                                                <div className="mt-1 ms-4">
                                                    <input
                                                        {...register("formacion_riesgos")}
                                                        type="radio"
                                                        name="formacion_riesgos"
                                                        value={"NO"}
                                                        defaultChecked={user.formacion_riesgos !== null ? (!user.formacion_riesgos ? true : false) : null}
                                                        id="formacion_riesgos"
                                                        onChange={(e) => {
                                                            setValue('formacion_riesgos', e.target.value);
                                                            if(e.target.checked === false) setValue('formacion_riesgos_year', '');
                                                            else setValue('formacion_riesgos_year', user.formacion_riesgos_year);
                                                        }}
                                                    />
                                                    &nbsp; <b>NO</b>
                                                </div>
                                            </div>
                                            :
                                            <p>{user.formacion_riesgos ? (user.formacion_riesgos === true ? 'SI' : 'NO') : '-'}</p>
                                        }
                                            
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formacion_riesgos_year">Año prevención de riesgos laborales</label>
                                        { (isEdit && watch('formacion_riesgos') === 'SI') ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("formacion_riesgos_year", { maxLength: 4 })} 
                                                    defaultValue={user.formacion_riesgos_year} />
                                            </>
                                            :
                                            <p>
                                                { isEdit && watch('formacion_riesgos') === 'NO' ? '-'
                                                    :
                                                    user.formacion_riesgos_year ? user.formacion_riesgos_year : '-'
                                                }</p>
                                        }
                                    </div>
                                </div>

                                <div className="w-100 d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="reconocimiento_medico">Reconocimiento médico</label>
                                        { (isEdit) ? 
                                            <>
                                                <select 
                                                    defaultValue={user.reconocimiento_medico}
                                                    {...register("reconocimiento_medico")}
                                                    onChange={handleChangeReconocimientoMedico}>
                                                    <option value={""}>Selecciona una opción...</option>
                                                    <option value={"T"}>SI</option>
                                                    <option value={"F"}>NO</option>
                                                    <option value={"N"}>NO INFORMADO</option>
                                                </select>
                                            </>
                                            :
                                            <p>{user.reconocimiento_medico !== null ? 
                                                (user.reconocimiento_medico === 'T' ? 'Si' 
                                                    : user.genero === 'F' ? 'No' 
                                                    : 'No informado') 
                                                : '-'}
                                            </p>
                                        } 
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="reconocimiento_medico_year">Año del reconocimiento médico</label>
                                        { (isEdit && watch('reconocimiento_medico') === 'T') ? 
                                            <>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    {...register("reconocimiento_medico_year", { maxLength: 4 })} 
                                                    defaultValue={user.reconocimiento_medico_year} />
                                            </>
                                            :
                                            <p>{user.reconocimiento_medico_year ? user.reconocimiento_medico_year : '-'}</p>
                                        }
                                            
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                                { (isEdit) ?
                                    <>
                                        <button type="button" className="btn btn-default me-3" onClick={() => handleCancelEdit()}>Cancelar</button>
                                        <button type="submit" className="btn btn-primary align-self-end">Actualizar</button>
                                    </>
                                    : null
                                }
                            </div>
                        </form>
                    </div>
                </div>
            }
        </div>
    )
}