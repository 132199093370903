import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import 'moment/locale/es';
import { CustomTooltip } from "../../../../../../../shared/helpers/functionalities";
import { Badge } from "@mui/material";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import {Companies, getCompanyById} from "../../../../../../../../data/company";


export default function Days({
    currDay,
    users
}) {
    const fiestasNacionales = useSelector(state => {
      return state.fiestas
    });
    const [days, setDays] = useState([]);

    useEffect(() => {
      const today = moment();
      let currentDays = [];
      let startOfMonth =  moment(currDay).startOf('month');
      let daysInMonth = moment(startOfMonth).daysInMonth();

      for (let day = 0; day < daysInMonth; day++) {
        if (day !== 0) {
          startOfMonth = moment(startOfMonth).add(1, 'day')
        }
    
        let calendarDay = {
          currentMonth: (startOfMonth.month === currDay.month),
          date: moment(startOfMonth),
          month: startOfMonth.month() + 1,
          number: startOfMonth.date(),
          selected: (startOfMonth.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')),
          year: startOfMonth.year()
        }
    
        currentDays.push(calendarDay);
      }

      setDays(currentDays);
    }, [currDay, users]);

    const isHoliday = (date, user) => {
      return fiestasNacionales.find(d => {
          let curr; 

          if(d.infinite) {
              curr = moment(moment(date).format('YYYY') + '-' + (d.month < 10 ? ('0' + d.month) : d.month) + '-' + (d.day < 10 ? ('0' + d.day) : d.day));
          } else {
              curr = moment(d.year+ '-' + (d.month < 10 ? ('0' + d.month) : d.month) + '-' + (d.day < 10 ? ('0' + d.day) : d.day));
          }

          return curr.isSame(date) && (d.empresa_id === null || d.empresa_id === user.departamento.empresa_id);
      })
    }

    const isBirthDay = (user, day) => {
      const currentDay = moment(day.date);
      const birthday = user.birthday ? moment(user.birthday) : null;
      // console.log(birtuser.birthdayhday)
      if(birthday) {
        return currentDay.month() === birthday.month() && currentDay.date() === birthday.date();
      } else return false;
    }


    //REFACTOR! 
    const getAbsences = (user, day) => {
      
      const ausencias = user?.createdAbsences?.find(timeOff => {
          const currentDay = moment(day.date)
          const start = moment(timeOff.fecha_inicio);
          const end = moment(timeOff.fecha_fin);
          const userCompany = getCompanyById(user.departamento.empresa_id);
          if(Companies.RECOMOTOR.name === userCompany.name) {
            if(currentDay.isBetween(start, end, 'days', '[]') && currentDay.day() !== 0 && currentDay.day() !== 6 && !isHoliday(currentDay, user) && !isBirthDay(currentDay,user)) {
              return true;
            } else return false;
          } else {
            // ahora conta festaius
            // if(currentDay.isBetween(start, end, 'days', '[]') && !isHoliday(currentDay, user) && !isBirthDay(currentDay,user)) {
              if(currentDay.isBetween(start, end, 'days', '[]')) {
              return true;
            } else return false;
          }
      });

      

      
      if(ausencias) {
        return (
            <span 
              key={'ausencia-symbol-' + ausencias.id }
              className={"circle "} 
              style={{backgroundColor: ausencias.tipoAusencia.color }}
            ></span> )  
        
      } else return null;
    }
 
    const isWeekend = (day) => {
      return day.date.day() === 0 || day.date.day() === 6
    }

    const backGroundColor = (day, user) => {
      if(day.selected) {
        return "today"
      } else if(isHoliday(day.date, user) ) {
        return "current holiday"
      } else if (isWeekend(day)) {
        return "current weekend"
      } else {
        return "current"
      }
    }

    return (
      <tbody>
        { users.map(user  => {
          const listDays = days.map((day) => {
            return (
              <td 
                key={'user-date-day-' + user.nombre + user.apellidos + '-' + day.number + '-' + day.month}
                className={"text-center calendar-day " + backGroundColor(day, user)}
              >
                <div className="d-flex h-100 flex-column align-items-center justify-content-between">
                  <p>{day.number}</p>
                  { (isBirthDay(user, day)) ?
                    <FontAwesomeIcon icon={faCakeCandles} size="1x" className="action" color="#ffd700" />
                    :
                    ''
                  }

                  <div className="w-100 d-flex align-items-center justify-content-center">
                    {getAbsences(user, day)}
                  </div>
                </div>
              </td>
            )
          })

          return (
            <tr key={'user-' + user.nombre + user.apellidos + '-days'}>
              <td>
                
                <CustomTooltip
                  title={
                      <>
                        <div className="w-100">
                        
                        </div>
                      </>
                  }>
                  <Badge color="primary" badgeContent={(user.dias_restantes - user.dias_pendientes)}>
                    {user.nombre + ' ' + user.apellidos.split(' ')[0]} &nbsp;&nbsp;&nbsp;&nbsp;
                  </Badge>
                </CustomTooltip>
              </td>
              {listDays}
            </tr>
          )
        }) }
      </tbody>
    )
}