import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "./utils";
import { toast } from 'react-toastify';
import { deleteDocumento, getDocumentos, getDocumentosUser, newDocumento } from "../views/Main/components/shared/services/documentos";
import { setErrorMessage } from "../views/shared/helpers/functionalities";

const initialDcomentos = null;

export const documentosAuxReducer = (state = initialDcomentos, action) => {
    switch(action.type){
        case "documentos/fullfilled": {
            return action.payload;
        }

        default:
            return state
    }
}

const asyncDocumentos = makeAsyncTypes('documentos');

const [setPending, setFullFilled, setError ] = asyncMac(asyncDocumentos);

export const documentosFetchingReducer = makeFetchingReducer([
    'documentos/pending', 
    'documentos/fullfilled', 
    'documentos/rejected'
]);

export const fetchDocumentos = (filters) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getDocumentos(filters)
        const data = await response.documentos;
        data.justificantes = await response.absences
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchDocumentosUsuario = (userId, filters = null) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getDocumentosUser(userId, filters)
        const data = await response.documentos;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const saveDocumento = (documento) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await newDocumento(documento)
        .catch((error) => {
            toast.error(setErrorMessage(error))
        });
        await response.data;
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        dispatch(fetchDocumentos());
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}


export const removeDocumento = (documentoId) => async dispatch => {
    try {
        const response = await deleteDocumento(documentoId)
        .catch((error) => {
            toast.error(setErrorMessage(error))
        });
        await response.data;
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        dispatch(fetchDocumentos());
    } catch (e) {
        toast.error(e.message)
    }
}

export const documentosReducer = documentosAuxReducer;
export const documentosStatusReducer = documentosFetchingReducer;