import { faCheck, faEye, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../shared/components/Loading";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../data/user';
import { fetchUsers } from "../../../../redux/users";


export default function Empleados() {
    const navigate = useNavigate();
    // const user = JSON.parse(localStorage.getItem('user'));
    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed)
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [userList, setUserList] = useState([]);

    const users = useSelector(state => {
        return state.users.entities
    });
    const usersStatus = useSelector(state => {
        return state.users.status
    });

    useEffect(() => {
        // if(!user.roles.includes('admin') && !user.roles.includes('rrhh') ) navigate('/', { replace: true });
        dispatch(fetchUsers());
    }, [dispatch]);

    useEffect(() => {
        setUserList(users);
    }, [users]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    if(value === "") {
        setUserList(users);
    } else {
        const newList = userList.filter(u => u.nombre?.toUpperCase().includes(value.toUpperCase()))
        setUserList(newList);
    }
  }
  
    return (
        <div className="w-100 content">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="position-relative ms-auto me-3">
                    <input 
                        type="text" 
                        name="searcher" 
                        className="form-control" 
                        value={search} 
                        onChange={(handleSearch)}
                    />
                    <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                </div>
            </div>

            { (usersStatus.loading === 'pending' || usersStatus.loading === 'idle') &&
                <Loading />
            }

            { (usersStatus.loading === 'succeed' || usersStatus.loading === 'rejected') &&
                <div className="w-100 table-responsive mt-3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>nombre</th>
                                <th>apellidos</th>
                                <th className="text-center">dni</th>
                                <th className="text-center">email</th>
                                <th className="text-center">teléfono</th>
                                <th className="text-center">activo</th>
                                <th className="text-center">acciones</th>
                            </tr>
                        </thead>

                        <tbody>
                            { userList?.map(user => {
                                return (
                                    <tr key={'user-' + user.uuid}>
                                        <td className="text-uppercase">{user.nombre}</td>
                                        <td className="text-uppercase">{user.apellidos}</td>
                                        <td className="text-uppercase text-center">{user.dni}</td>
                                        <td className="text-center">{user.email}</td>
                                        <td className="text-center">{user.telefono}</td>
                                        <td className="text-center">
                                            { (user.active) ?
                                                <FontAwesomeIcon icon={faCheck} size="1x" color="#00986C" />
                                                : 
                                                <FontAwesomeIcon icon={faTimes} size="1x" color="#dc3545" />
                                            }
                                        </td>
                                        <td className="text-center">
                                            <Link to={"/empleado/" + user.uuid} title="Ver ficha">
                                                <FontAwesomeIcon icon={faEye} size="1x" className="action" color="#215732" />
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}